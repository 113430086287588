@import '~@sg-bootstrap/icons/dist/index.min.css';
@import '~@sg-bootstrap/core/build/sg-bootstrap-standard.min.css';
@import '~@sg-bootstrap/ag-grid/dist/theme-standard.min.css';
@import './bootstrap.datepicker.min.css';
@import '~daterangepicker/daterangepicker.css';
$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome.scss';

html {
    position: relative;
    min-height: 100%;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: calc(100vh - 130px);
    background-color: #f4f4f4;
    width: 100%;
    padding-top: 76px;
}

.panel {
    background-color: #f5f5f5;
}

.panel > .panel-body {
    padding-bottom: 0;
    label {
        font-weight: 600;
    }
    span {
        font-weight: 600;
    }
}

.panel > .panel-heading {
    padding-left: 1.3em;
    color: #333;
    background-color: #f5f5f5;
    &.collapsible {
        cursor: pointer;
    }
    .no-padding {
        padding: 0;
    }
}

.quick-search {
    vertical-align: top; //For IE alignment
    max-width: 18em;
    display: inline-block;
    position: relative;
    margin-bottom: 0.5em;
}

.command-button {
    width: 3em;
    display: inline-block;
}

.command-button-grid {
    width: 3em;
    height: 1.8em;
    display: inline-block;
}

.inline-button {
    display: inline-block;
}

.no-left-padding {
    padding-left: 0 !important;
}

.no-right-padding {
    padding-right: 0 !important;
}

.datepicker,
.combo-box-scroll {
    z-index: 1000 !important;
}

.contextualHelp {
    max-height: 500px !important;
    max-width: 250px;
    overflow: auto !important;
    font-size: 12px;
}
.contextualHelp h1 {
    font-size: 18px;
    font-weight: Bold;
    color: #e60028;
}
.contextualHelp h2 {
    font-size: 16px;
    font-weight: Bold;
}
.contextualHelp ul {
    padding-left: 15px;
}
.contextualHelp li {
    list-style: disc;
    padding: 2px;
    margin: 2px;
}
.gal-busy .modal-dialog {
    text-align: center;
}

// ngx-chips tag-input-form width increased from default
.ng2-tag-input__text-input {
    height: 28px !important;
    width: 40em;
    background-color: #e7eaeb;
    padding: 0 0 0 2px !important;
    font-family: 'Source Sans Pro', Arial, sans-serif !important;
}

delete-icon:hover {
    transform: scale(1.2) translateY(-2px) !important;
}

.ng2-tag-input.tag-input-custom-theme tag delete-icon svg {
    vertical-align: middle !important;
    height: 24px !important;
}

.ng2-tag-input.tag-input-custom-theme tag-input-form {
    //width: 100% !important;
}
.ng2-tag-input.tag-input-custom-theme .ng2-tags-container {
    cursor: text;
    padding-bottom: 2px;
}
.h-auto {
    height: auto;
}

ng-component {
    width: 100%;
}

.sgb-notifications {
    position: fixed;
    z-index: 9999;

    &.top-right {
        right: 10px;
        top: 25px;
    }

    &.top-left {
        left: 10px;
        top: 25px;
    }

    &.bottom-left {
        left: 10px;
        bottom: 25px;
    }

    &.bottom-right {
        right: 10px;
        bottom: 25px;
    }

    > div {
        position: relative;
        z-index: 9999;
        margin: 5px 0;
    }

    &.center {
        top: 48%;
        left: 0;
        width: 100%;

        > div {
            margin: 5px auto;
            width: 20%;
            text-align: left;
        }
    }

    &.top-center {
        top: 25px;
        left: 0;
        width: 100%;

        > div {
            margin: 5px auto;
            width: 20%;
            text-align: left;
        }
    }

    &.bottom-center {
        bottom: 25px;
        left: 0;
        width: 100%;

        > div {
            margin: 5px auto;
            width: 20%;
            text-align: left;
        }
    }
}

.title-spacer {
    height: 52px;
}

button > i.fa-search {
    margin-right: 5px;
}
ag-grid-angular .ag-grid-cell-link {
    span:hover,
    i:hover {
        cursor: pointer;
    }
}

//fix Firefox issue
iframe[data-sgwt-connect-renewal-frame=''] {
    display: block !important;
    z-index: -1231231;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    opacity: 0;
}

.spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
}
